import { Service, SignupStep } from "@yoco/sawubona-sdk";
import { formatRoute } from "react-router-named-routes";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { UserJourney } from "@/signup/types";
import { useAppConfigStore, useSignupStore } from "@/signup/store";
import CONFIG from "@/config";

export interface StepState {
  steps: SignupStep[];
  reset: () => void;
  refreshSteps: () => void;
  activeStep: SignupStep;
  setActiveStep: (activeStep: SignupStep) => void;
  getStepAfter: (step: SignupStep) => SignupStep | null;
  getNextStep: () => SignupStep | null;
  getNextStepLink: () => string;
  getStepBefore: (step: SignupStep) => SignupStep | null;
  getPreviousStep: () => SignupStep | null;
  getPreviousStepLink: () => string;
  isOnFinalStep: () => boolean;
}

export const useStepStore = create<StepState>()(
  devtools(
    (set, get) => ({
      steps: [],
      reset: () => {
        get().setActiveStep(SignupStep.HomePage);
      },
      refreshSteps: () => {
        const { signup } = useSignupStore.getState();
        const {
          userJourney,
          skipServiceSelectionStep,
          skipProductCatalogueStep,
        } = useAppConfigStore.getState();

        const steps: SignupStep[] = [];

        switch (userJourney) {
          case UserJourney.CINNAMON:
          case UserJourney.EGYPT:
            steps.push(SignupStep.HomePage);
            steps.push(SignupStep.BusinessPreQualificationPage);
            steps.push(SignupStep.BusinessNamePage);
            steps.push(SignupStep.TradingAddressPage);
            steps.push(SignupStep.BusinessTypePage);
            steps.push(SignupStep.BusinessOwnerPage);
            steps.push(SignupStep.BusinessCategoryPage);
            steps.push(SignupStep.CompletePage);
            break;
          case UserJourney.MANKAI:
            steps.push(SignupStep.HomePage);
            steps.push(SignupStep.BusinessPreQualificationPage);
            steps.push(SignupStep.BusinessNamePage);
            steps.push(SignupStep.TradingAddressPage);
            steps.push(SignupStep.BusinessTypePage);
            steps.push(SignupStep.BusinessOwnerPage);
            steps.push(SignupStep.BusinessCategoryPage);
            steps.push(SignupStep.CompletePage);
            break;
          case UserJourney.DEVELOPER:
            steps.push(SignupStep.HomePage);
            steps.push(SignupStep.BusinessPreQualificationPage);
            steps.push(SignupStep.DeveloperDetailsPage);
            steps.push(SignupStep.CompletePage);
            break;
          case UserJourney.XNEELO:
            steps.push(SignupStep.XneeloLandingPage);
            steps.push(SignupStep.BusinessPreQualificationPage);
            steps.push(SignupStep.BusinessNamePage);
            steps.push(SignupStep.TradingAddressPage);
            steps.push(SignupStep.BusinessTypePage);
            steps.push(SignupStep.BusinessOwnerPage);
            steps.push(SignupStep.BusinessCategoryPage);
            steps.push(SignupStep.CompletePage);
            break;
          case UserJourney.YOCOSHOP:
            steps.push(SignupStep.HomePage);
            steps.push(SignupStep.BusinessPreQualificationPage);
            steps.push(SignupStep.BusinessNamePage);
            steps.push(SignupStep.TradingAddressPage);
            steps.push(SignupStep.BusinessTypePage);
            steps.push(SignupStep.BusinessOwnerPage);
            steps.push(SignupStep.BusinessCategoryPage);
            steps.push(SignupStep.DeliveryPage);
            steps.push(SignupStep.PaymentPage);
            steps.push(SignupStep.CompletePage);
            break;
          case UserJourney.LITCHI:
          case UserJourney.MANGO:
          case UserJourney.APP:
          default:
            steps.push(SignupStep.HomePage);
            steps.push(SignupStep.BusinessPreQualificationPage);

            if (!skipServiceSelectionStep) {
              steps.push(SignupStep.ServiceSelectionPage);
            }

            if (signup?.services.includes(Service.WEBSITE)) {
              steps.push(SignupStep.BusinessUrlPage);
            }

            steps.push(SignupStep.BusinessNamePage);
            steps.push(SignupStep.TradingAddressPage);
            steps.push(SignupStep.BusinessTypePage);
            steps.push(SignupStep.BusinessOwnerPage);
            steps.push(SignupStep.BusinessCategoryPage);

            if (signup?.services.length === 0) {
              steps.push(SignupStep.ServiceSelectionPage);
            }

            if (
              signup?.services.includes(Service.CARD_MACHINE) &&
              signup?.services.includes(Service.READER_REGISTRATION)
            ) {
              if (!skipProductCatalogueStep) {
                steps.push(SignupStep.ProductCataloguePage);
              }
              steps.push(SignupStep.DeliveryPage);
              steps.push(SignupStep.PaymentPage);
            } else if (signup?.services.includes(Service.CARD_MACHINE)) {
              if (!skipProductCatalogueStep) {
                steps.push(SignupStep.ProductCataloguePage);
              }
              steps.push(SignupStep.DeliveryPage);
              steps.push(SignupStep.PaymentPage);
            } else if (signup?.services.includes(Service.READER_REGISTRATION)) {
              const hasSerialNumber = !!signup?.serial_number;

              if (!hasSerialNumber) {
                steps.push(SignupStep.RegisterReaderPage);
              }
            }

            steps.push(SignupStep.CompletePage);
        }

        set({ steps }, false, "refreshSteps");
      },
      activeStep: SignupStep.HomePage,
      setActiveStep: activeStep => {
        set({ activeStep }, false, "setActiveStep");
      },
      getStepAfter: step => {
        const { steps } = get();
        const index = steps.indexOf(step);
        return steps[index + 1] || null;
      },
      getNextStep: () => {
        const { activeStep } = get();

        if (!activeStep) {
          return null;
        }

        return get().getStepAfter(activeStep);
      },
      getNextStepLink: () => {
        const nextStep = get().getNextStep();

        if (!nextStep) {
          return "";
        }

        const { signup } = useSignupStore.getState();

        return formatRoute(nextStep, {
          id: signup?.id,
        });
      },
      getStepBefore: step => {
        const { steps } = get();
        const index = steps.indexOf(step);
        return steps[index - 1] || null;
      },
      getPreviousStep: () => {
        const { activeStep } = get();

        if (!activeStep) {
          return null;
        }

        return get().getStepBefore(activeStep);
      },
      getPreviousStepLink: () => {
        const previousStep = get().getPreviousStep();

        if (!previousStep) {
          return "";
        }

        const { signup } = useSignupStore.getState();

        return formatRoute(previousStep, {
          id: signup?.id,
        });
      },
      isOnFinalStep: () => {
        return get().getNextStep() === SignupStep.CompletePage;
      },
    }),
    { name: "StepStore", enabled: CONFIG.stateDebugEnabled }
  )
);
