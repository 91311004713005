import { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import creditCardIcon from "@/shop/pages/PaymentPage/images/credit-card.svg";
import eftIcon from "@/shop/pages/PaymentPage/images/eft.svg";
import instantEFTIcon from "@/shop/pages/PaymentPage/images/instant-eft.svg";
import PaymentMethodNavItemComponent from "@/shop/pages/PaymentPage/PaymentMethodNavItem";
import { useCartStore } from "@/shop/store";
import { PaymentMethodNavItem } from "@/shop/types";
import React, { useEffect, useRef } from "react";

const navItems: PaymentMethodNavItem[] = [
  {
    paymentMethod: PaymentMethod.CREDIT_CARD_BLACKBIRD,
    secondaryPaymentMethods: [
      PaymentMethod.PAYMENT_TERMS,
      PaymentMethod.CARD_ON_DELIVERY,
    ],
    label: "Card",
    subLabel: "VISA and Mastercard accepted",
    icon: creditCardIcon,
  },
  {
    paymentMethod: PaymentMethod.EFT,
    label: "EFT",
    subLabel: "Pay from your bank account",
    icon: eftIcon,
  },
  {
    paymentMethod: PaymentMethod.INSTANT_EFT_STITCH,
    label: "Instant EFT",
    subLabel: "Securely pay with Instant EFT",
    icon: instantEFTIcon,
  },
  {
    paymentMethod: PaymentMethod.INSTANT_EFT_BLACKBIRD,
    label: "Instant EFT",
    subLabel: "Securely pay with Instant EFT",
    icon: instantEFTIcon,
  },
];

const useStore = () => ({
  defaultPaymentMethod: useCartStore(
    state => state.cart?.default_payment_method
  ),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
});

type Props = {
  onChange?: (paymentMethod: PaymentMethod) => void;
};

const PaymentMethodNav: React.FC<Props> = ({
  onChange: onChangeCallback,
}: Props) => {
  const {
    defaultPaymentMethod,
    availablePaymentMethods,
    intendedPaymentMethod,
    setIntendedPaymentMethod,
  } = useStore();

  const isOnChangeFiredForDefaultPaymentMethod = useRef(false);

  useEffect(() => {
    if (defaultPaymentMethod) {
      // We only want to set the default payment method as the active nav item once.  We therefore use a ref to keep
      // track of this across renders.
      // See https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
      if (!isOnChangeFiredForDefaultPaymentMethod.current) {
        isOnChangeFiredForDefaultPaymentMethod.current = true;

        const defaultNavItem = navItems.find(
          navItem => navItem.paymentMethod === defaultPaymentMethod
        );

        if (defaultNavItem) {
          const { paymentMethod } = defaultNavItem;

          setIntendedPaymentMethod(paymentMethod);

          if (onChangeCallback) {
            onChangeCallback(paymentMethod);
          }
        }
      }
    }
  }, [
    defaultPaymentMethod,
    onChangeCallback,
    isOnChangeFiredForDefaultPaymentMethod,
    setIntendedPaymentMethod,
  ]);

  const onChange = (paymentMethod: PaymentMethod) => {
    setIntendedPaymentMethod(paymentMethod);

    if (onChangeCallback) {
      onChangeCallback(paymentMethod);
    }
  };

  const visibleNavItems = navItems.filter(navItem =>
    availablePaymentMethods?.includes(navItem.paymentMethod)
  );

  const isChecked = React.useCallback(
    (navItem: PaymentMethodNavItem) => {
      return (
        navItem.paymentMethod === intendedPaymentMethod ||
        !!navItem.secondaryPaymentMethods?.includes(intendedPaymentMethod)
      );
    },
    [intendedPaymentMethod]
  );

  return visibleNavItems.length > 0 ? (
    <div className="md:flex flex-row pb-6 sm:items-center justify-start">
      {visibleNavItems.map(navItem => (
        <PaymentMethodNavItemComponent
          key={navItem.paymentMethod}
          name="payment_method"
          type="radio"
          value={navItem.paymentMethod}
          label={navItem.label}
          description={navItem.subLabel}
          image={navItem.icon}
          onChange={() => onChange(navItem.paymentMethod)}
          checked={isChecked(navItem)}
          data-testid={makeTestID("payment", "method", navItem.label)}
        />
      ))}
    </div>
  ) : null;
};

export default PaymentMethodNav;
