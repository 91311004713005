import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Input from "@/shared/components/form/Input";

import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Radio from "@/shared/components/form/Radio";
import RadioGroup from "@/shared/components/form/RadioGroup";
import Select from "@/shared/components/form/Select";
import BaseSubmitButton from "@/shared/components/form/SubmitButton";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessPreQualificationPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessPreQualificationPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_pre_qualification_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessPreQualificationPage,
    card_machine_use: signup?.card_machine_use || "",
    approximate_volume_of_card_payments:
      signup?.approximate_volume_of_card_payments ||
      CARD_PAYMENT_VOLUME_CHOICES[0].value,
    lead_source:
      signup?.pre_qualification?.questions?.find(item => {
        return item.question == QUESTION_LABELS.lead_source;
      }).answer || "",
  };

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup({
      ...values,
      pre_qualification: {
        ...signup?.pre_qualification,
        version: "1.0.1",
        source: "signup",
        questions: [
          {
            answer: values.card_machine_use,
            order: 1,
            question: QUESTION_LABELS.card_machine_use,
          },
          {
            answer: values.approximate_volume_of_card_payments,
            order: 2,
            question: QUESTION_LABELS.approximate_volume_of_card_payments,
          },
          {
            answer: values.lead_source,
            order: 3,
            question: QUESTION_LABELS.lead_source,
          },
        ],
      },
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_business_pre_qualification_next_button_clicked"
      );

      navigate(getNextStepLink());
    }
  };

  const onSkip = async () => {
    Segment.track("web_signup_business_pre_qualification_skip_button_clicked");
    navigate(getNextStepLink());
  };

  const handleDoesCurrentlyAcceptCardPaymentsSelected = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any
  ) => {
    const { name, value } = e.target;

    // Formik doesn't currently support parsing or casting a value - see https://github.com/formium/formik/pull/2255
    formik.setFieldValue(name, String(value) === "true");
  };

  const schema = yup.object({
    card_machine_use: yup
      .string()
      .oneOf(["switching_provider", "backup_or_events", "new_to_card_payments"])
      .required("You must select an option."),
    approximate_volume_of_card_payments: yup
      .string()
      .required("You must select an option."),
    lead_source: yup
      .string()
      .label("Heard about Yoco")
      .required("required")
      .max(25, "Cannot be longer than 25 characters"),
  });

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="We'd like to know a bit more about you"
          description={
            <p className="py-2 text-base">
              These questions help us better serve you.
            </p>
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formik: any) => {
            return (
              <Form>
                <RadioGroup name="card_machine_use">
                  <p className="mb-2 font-bold">
                    {QUESTION_LABELS.card_machine_use}
                  </p>
                  <div className="pb-6">
                    <Field
                      name="card_machine_use"
                      type="radio"
                      value="switching_provider"
                      label="I am switching from an existing provider to Yoco"
                      component={Radio}
                    />

                    <Field
                      name="card_machine_use"
                      type="radio"
                      value="backup_or_events"
                      label="I plan on using Yoco as a backup or for events"
                      component={Radio}
                    />

                    <Field
                      name="card_machine_use"
                      type="radio"
                      value="new_to_card_payments"
                      label="I am new to card payments"
                      component={Radio}
                    />
                  </div>
                </RadioGroup>
                <div className="pb-6">
                  <p className="mb-2 font-bold">
                    {QUESTION_LABELS.approximate_volume_of_card_payments}
                  </p>
                  <Field
                    name="approximate_volume_of_card_payments"
                    component={Select}
                    options={CARD_PAYMENT_VOLUME_CHOICES}
                  />
                </div>
                <div className="pb-6">
                  <p className="mb-2 font-bold">
                    {QUESTION_LABELS.lead_source}
                  </p>
                  <Field name="lead_source" component={Input} maxlength="25" />
                </div>
                <SubmitAndSkipButtonGroup onSkip={onSkip} />
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

type SubmitAndSkipButtonGroupProps = {
  onSkip?: () => void;
};
// This component re-implements the responsive logic of the <SubmitButton /> component, but incl
const SubmitAndSkipButtonGroup: React.FC<SubmitAndSkipButtonGroupProps> = ({
  onSkip,
}: SubmitAndSkipButtonGroupProps) => {
  const { signup } = useStore();

  const buttons = (
    <div className="flex flex-row space-x-4 items-stretch">
      <BaseSubmitButton testID={makeTestID("businessPreQualification", "next")}>
        Next
      </BaseSubmitButton>
    </div>
  );

  const isFixed = signup?.user_journey === UserJourney.BANANA;
  const submitButtonClasses = `md:hidden left-0 w-full p-4 bg-white z-10 ${
    isFixed ? "fixed bottom-0" : ""
  }`;

  return signup?.user_journey === UserJourney.CINNAMON ||
    signup?.user_journey === UserJourney.BANANA ? (
    <>
      <div className={submitButtonClasses}>{buttons}</div>
      <div className="pt-10 md:pt-0" />
      <div className="hidden md:block">{buttons}</div>
    </>
  ) : (
    buttons
  );
};

const QUESTION_LABELS = {
  card_machine_use: "How do you plan on using Yoco?",
  approximate_volume_of_card_payments:
    "What is your total expected volume of card payments per month?",
  lead_source: "Where did you hear about Yoco?",
};

const CARD_PAYMENT_VOLUME_CHOICES = [
  {
    value: "",
    label: "Select an option",
  },
  {
    value: "Less than R10,000",
    label: "Less than R10,000",
  },
  {
    value: "R10,000 - R50,000",
    label: "R10,000 - R50,000",
  },
  {
    value: "R50,000 - R100,000",
    label: "R50,000 - R100,000",
  },
  {
    value: "R100,000 - R200,000",
    label: "R100,000 - R200,000",
  },
  {
    value: "R200,000 - R500,000",
    label: "R200,000 - R500,000",
  },
  {
    value: "R500,000 - R1,000,000",
    label: "R500,000 - R1,000,000",
  },
  {
    value: "R1,000,000 +",
    label: "R1,000,000 +",
  },
];

export default withFetchSignup(BusinessPreQualificationPage);
