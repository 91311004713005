import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessNamePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessNamePage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_name_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessNamePage,
    business_name: signup?.business_name || "",
  };

  yup.addMethod(
    yup.string,
    "validBusinessName",
    Validator.validateBusinessName
  );

  const schema = yup.object({
    business_name: (yup as any)
      .string()
      .required()
      .label("business name")
      .validBusinessName(),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup(values);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_name_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="What's your business called?"
          description={
            <>
              <p className="py-2 text-base">
                Not sure yet? That's okay, you can update this anytime in your
                Yoco profile.
              </p>
              <p>
                It can be your name, your trading name, or whatever your
                customers best know you by.
              </p>
            </>
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formik: any) => {
            return (
              <Form>
                <div className="mb-8">
                  <Field
                    name="business_name"
                    placeholder="Business trading name *"
                    component={Input}
                    data-testid={makeTestID("businessName", "businessName")}
                  />
                </div>

                <div>
                  <p>
                    This will be displayed on your <b>(free)</b> Yoco receipts
                    when you transact.
                  </p>
                  <br />
                </div>

                <SubmitButton testID={makeTestID("businessName", "next")}>
                  Next
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(BusinessNamePage);
