import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CardMain from "@/shared/components/CardMain";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Input from "@/shared/components/form/Input";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import SubmitButton from "@/signup/components/SubmitButton";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const schema = yup.object({
  owner_first_name: yup.string().required().label("business owner name(s)"),
  owner_last_name: yup.string().required().label("business owner surname"),
});

const BusinessOwnerPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessOwnerPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_owner_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessOwnerPage,
    owner_first_name: signup?.owner_first_name || "",
    owner_last_name: signup?.owner_last_name || "",
  };

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup({
      ...values,
      pre_qualification: {
        ...signup?.pre_qualification,
        name: `${values.owner_first_name} ${values.owner_last_name}`,
      },
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_owner_next_button_clicked");

      // The marketing team currently uses this event to track that this signup is now an "opportunity".  Whilst we
      // have a plan to move to a semantic event indicating that an opportunity has been created, we need this as an
      // interim solution.
      Segment.trackWithSignup(
        "web_signup_business_details_info_next_button_clicked"
      );

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <CardMain>
        <StepProgressBar />
        <Header
          title="What's your name?"
          description="You must be the sole trader, owner or a director, partner, member or trustee of the business you're signing up for."
        />

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            return (
              <Form>
                <div className="mb-8">
                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded">
                      <Field
                        name="owner_first_name"
                        placeholder="First Name(s) *"
                        component={Input}
                        data-testid={makeTestID(
                          "businessOwner",
                          "businessOwner",
                          "firstName"
                        )}
                      />
                    </div>
                    <div className="w-full sm:w-1/2 sm:pl-2 rounded">
                      <Field
                        name="owner_last_name"
                        placeholder="Surname *"
                        component={Input}
                        data-testid={makeTestID(
                          "businessOwner",
                          "businessOwner",
                          "lastName"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <p>
                    We'll use these details to verify you, and get you paid.
                  </p>
                  <div className="font-medium">
                    <p className="py-4">
                      Not the owner? Get more details about what you need to
                      sign up and get set up to get paid out{" "}
                      <a
                        href="https://support.yoco.help/en/articles/109534-setup-verification-basics"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        here
                      </a>
                    </p>
                  </div>
                </div>

                <SubmitButton testID={makeTestID("businessOwner", "next")}>
                  Next
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </CardMain>
    </Layout>
  );
};

export default withFetchSignup(BusinessOwnerPage);
