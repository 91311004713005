import CardMain from "@/shared/components/CardMain";
import SubmitButton from "@/shared/components/form/SubmitButton";
import Header from "@/shared/components/Header";
import { makeTestID } from "@/shared/utils/development";
import CartSummaryMobile from "@/shop/components/CartSummaryMobile";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import Layout from "@/shop/components/Layout";
import StepProgressBar from "@/shop/components/StepProgressBar";
import ProductGrid from "@/shop/pages/ProductCataloguePage/ProductGrid";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import { useFeatureFlags } from "@yoco/feature-flags";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";

const ProductCataloguePage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { updateCart } = useCartStore.getState();
  const features = useFeatureFlags().getEnabledFeatureNames();

  const onSubmit = async () => {
    const { cart } = useCartStore.getState();
    if (cart) {
      if (features) {
        await updateCart({ feature_flags: features });
      }

      navigate(
        formatRoute(ShopRoute.DeliveryPage, {
          id: cart.id,
        })
      );
    }
  };

  const button = (
    <SubmitButton
      onClick={onSubmit}
      block
      testID={makeTestID("productCatalogue", "checkout")}
    >
      Checkout
    </SubmitButton>
  );

  return (
    <Layout>
      <CardMain wider>
        <div className="p-8">
          <StepProgressBar number={1} />
          <Header title="Choose a card machine" />
          <ProductGrid />
          <CartSummaryMobileOffset />
        </div>
      </CardMain>
      <CheckoutSidePanel button={button} />
      <CartSummaryMobile button={button} />
    </Layout>
  );
};

export default ProductCataloguePage;
