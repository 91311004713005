import CONFIG from "@/config";
import shop, { Business } from "@/shared/shop/api";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface AppConfigState {
  business: Business | null;
  storefront: string | null;
  productTypes: string[];
  isEmbed: boolean;
  isInApp: boolean;
  isInHub: boolean;
  expandMobileSummary: boolean;
  paymentSuccessRedirect: string | null;
  setExpandMobileSummary: (expandMobileSummary: boolean) => void;
  setBusiness: (business: Business | null) => void;
  fetchBusiness: (id: string) => Promise<boolean>;
  setStorefront: (storefront: string | null) => void;
  setIsEmbed: (isEmbed: boolean) => void;
  setIsInApp: (isInApp: boolean) => void;
  setIsInHub: (isInHub: boolean) => void;
  setProductTypes: (productTypes: string[]) => void;
  setPaymentSuccessRedirect: (paymentSuccessRedirect: string | null) => void;
}

export const useAppConfigStore = create<AppConfigState>()(
  devtools(
    set => ({
      business: null,
      storefront: "signup",
      isEmbed: false,
      isInApp: sessionStorage.getItem("isInApp") === "true",
      isInHub: sessionStorage.getItem("isInHub") === "true",
      expandMobileSummary: false,
      productTypes: ["card_machine"],
      paymentSuccessRedirect: sessionStorage.getItem("paymentSuccessRedirect"),
      setExpandMobileSummary: expandMobileSummary => {
        set({ expandMobileSummary }, false, "setExpandMobileSummary");
      },
      setBusiness: business => {
        set({ business }, false, "setBusiness");
      },
      fetchBusiness: async id => {
        try {
          const business = await shop.businesses.get(id);
          set({ business }, false, "fetchBusiness");

          return true;
        } catch (error) {
          return false;
        }
      },
      setStorefront: storefront => {
        set({ storefront }, false, "setStorefront");
      },
      setIsEmbed: isEmbed => {
        set({ isEmbed }, false, "setIsEmbed");
      },
      setIsInApp: isInApp => {
        sessionStorage.setItem("isInApp", `${isInApp}`);
        set({ isInApp }, false, "setIsInApp");
      },
      setIsInHub: isInHub => {
        sessionStorage.setItem("isInHub", `${isInHub}`);
        set({ isInHub }, false, "setIsInHub");
      },
      setProductTypes: productTypes => {
        set({ productTypes }, false, "setProductTypes");
      },
      setPaymentSuccessRedirect: paymentSuccessRedirect => {
        sessionStorage.setItem(
          "paymentSuccessRedirect",
          `${paymentSuccessRedirect}`
        );
        set({ paymentSuccessRedirect }, false, "setPaymentSuccessRedirect");
      },
    }),
    { name: "ShopAppConfigStore", enabled: CONFIG.stateDebugEnabled }
  )
);
