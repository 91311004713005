import React, { useEffect, useState } from "react";
import buttonLoaderImage from "@/assets/images/loader.gif";
import { useAppConfigStore, useSignupStore } from "@/signup/store";
import { useWaitUntilMerchantAccountIsCreated } from "@/signup/utils/hooks";
import BaseSubmitButton from "@/shared/components/form/SubmitButton";

const useStore = () => ({
  yocoAuthToken: useSignupStore(state => state.yocoAuthToken),
  isInHub: useAppConfigStore(state => state.isInHub),
});

const ContinueToAppButton: React.FC<any> = () => {
  const { yocoAuthToken, isInHub } = useStore();
  const { isMerchantAccountCreated, merchantAccountError } =
    useWaitUntilMerchantAccountIsCreated();
  const [isCompleteSetupSelected, setCompleteSetupSelected] =
    useState<boolean>(false);

  const onClickContinueToAppButton = () => {
    setCompleteSetupSelected(true);
  };

  useEffect(() => {
    if (!isMerchantAccountCreated || !isCompleteSetupSelected) {
      return;
    }

    const baseRedirectURL = isInHub
      ? "yocohub://login/landing"
      : "yocopos://link/autoLogin";
    const authPart =
      yocoAuthToken && isMerchantAccountCreated ? `/?yat=${yocoAuthToken}` : "";
    window.location.href = `${baseRedirectURL}${authPart}`;
  }, [isCompleteSetupSelected, yocoAuthToken, isMerchantAccountCreated]);

  return (
    <div className="left-0 w-full p-4 bg-white z-10 fixed bottom-0 md:relative md:p-0">
      <BaseSubmitButton
        onClick={onClickContinueToAppButton}
        disabled={isCompleteSetupSelected}
      >
        <span className="font-bold text-base">
          {!isCompleteSetupSelected ? (
            "Complete setup now"
          ) : (
            <img
              alt="Loading..."
              className="mx-auto"
              width={25}
              src={buttonLoaderImage}
            />
          )}
        </span>
      </BaseSubmitButton>
    </div>
  );
};

export default ContinueToAppButton;
