import CONFIG from "@/config";
import CardMain from "@/shared/components/CardMain";
import { useCartStore } from "@/shop/store";
import Layout from "@/signup/components/Layout";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { UserJourney } from "@/signup/types";
import { sawubona, SignupStep } from "@yoco/sawubona-sdk";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import AutoLoginToAppContent from "./AutoLoginToAppContent";
import CardMachineInAppContent from "./CardMachineInAppContent";
import InAppContent from "./InAppContent";
import InKhumoAppContent from "./InKhumoAppContent";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  cart: useCartStore(state => state.cart),
  isInApp: useAppConfigStore(state => state.isInApp),
  isInHub: useAppConfigStore(state => state.isInHub),
  yocoAuthToken: useSignupStore(state => state.yocoAuthToken),
  setYocoAuthToken: useSignupStore(state => state.setYocoAuthToken),
  userJourney: useAppConfigStore(state => state.userJourney),
});

const CompletePage: React.FC<any> = () => {
  const {
    setActiveStep,
    signup,
    isInApp,
    isInHub,
    yocoAuthToken,
    setYocoAuthToken,
    userJourney,
  } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.CompletePage);
  }, [setActiveStep]);

  // When we have a completed signup, generate a yoco auth token that will be used
  // by the various completion screens to auto-login to the portal/app
  useEffect(() => {
    if (!yocoAuthToken && signup) {
      const id = signup?.id || "";
      sawubona.signups.generateYocoAuthToken(id).then(token => {
        setYocoAuthToken(token);
      });
    }
  }, [signup, yocoAuthToken, setYocoAuthToken]);

  useEffect(() => {
    if (yocoAuthToken) {
      // We've explicitly called this yat to be introduce a bit of security by obfuscatory.  The "yat" cookie,
      // or "Yoco Auth Token" can be read by any service, to identify a user, or exchange that user's short lived
      // yat for a long lived session token.
      const expiryDate = new Date(new Date().getTime() + 5 * 60 * 1000); // 5 minutes
      Cookies.set("yat", yocoAuthToken, {
        expires: expiryDate,
        domain: CONFIG.baseDomain,
      });
    }
  }, [yocoAuthToken]);

  if (isInApp || isInHub) {
    return <InAppContent />;
  }
  if (userJourney === UserJourney.CINNAMON) {
    return <InKhumoAppContent />;
  }
  if (userJourney === UserJourney.MANKAI) {
    return <CardMachineInAppContent />;
  }
  return (
    <Layout>
      <div className="flex flex-col">
        <CardMain>
          <AutoLoginToAppContent />;
        </CardMain>
        <div className="hidden sm:block" style={{ minWidth: "650px" }} />
      </div>
    </Layout>
  );
};

export default withFetchSignup(CompletePage);
