import { Config } from "@/config";
import { UserJourney } from "@/signup/types";
import { CountryCode } from "@/libs/sawubona-sdk/types";

// "camel" is used as the base feature environment.
const DEFAULT_CONFIG: Config = {
  apiURL: "https://hello.camel.yocobeta.co.za/sawubona",
  autoSendAppDownloadSMS: false,
  baseDomain: "yocobeta.co.za",
  blackbirdPublicKey: "pk_test_8f133808J4bGznOb4c45",
  blackbirdSDKUrl:
    "https://blackbird-web-sdk-staging.netlify.app/sdk/v1/blackbird-web-sdk.js",
  branchKey: "key_test_geKnqdsLE8tRiAWmNaS8zohlwucNbiKh",
  cookieYesKey: "",
  countryCode: CountryCode.SouthAfrica,
  defaultUserJourney: UserJourney.BANANA,
  gitCommitSha: process.env.REACT_APP_GIT_COMMIT_SHA,
  googlePlacesPublicKey: "AIzaSyBe-XkLMzqkcRPyuMJNCcBe6ffCiitXKxs",
  googleTagManagerContainerID: "GTM-PZQ88R2",
  appURL: "https://app.camel.yocobeta.co.za",
  paymentTermsBlackbirdPublicKey: "pk_test_123abc456def789gff",
  portalURL: "https://portal.camel.yocobeta.co.za",
  segmentPublicKey: "iUfhZtaL8Kh4LrXJGoV3CHUweauX7xM6",
  sentryDSN:
    "https://836fd71a585f4287a37621a4500374be@o406491.ingest.sentry.io/5544585",
  stateDebugEnabled: true,
  vwoAccountId: "513333",
};

export const makeFeatureEnvConfig = (
  envName: string,
  customConfig?: {
    [key: string]: any;
  }
): Config => {
  return {
    ...DEFAULT_CONFIG,
    ...{
      apiURL: `https://hello.${envName}.yocobeta.co.za/sawubona`,
      portalURL: `https://portal.${envName}.yocobeta.co.za`,
      appURL: `https://app.${envName}.yocobeta.co.za`,
    },
    ...customConfig,
  };
};
