import React from "react";
import logoImage from "./logo.svg";

const Logo: React.FC<any> = () => {
  const redirectToWebsite = () => {
    window.location.href = "https://www.yoco.com/za/";
  };
  return (
    <div
      className="logo"
      role="button"
      tabIndex={0}
      onClick={redirectToWebsite}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === "") {
          redirectToWebsite();
        }
      }}
    >
      <img
        className="yoco-logo"
        style={{
          height: "36px",
        }}
        src={logoImage}
        alt="Yoco Logo"
      />
    </div>
  );
};

export default Logo;
