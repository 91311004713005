import React from "react";

type Props = {
  label: string;
  description: string | React.ReactElement;
  image?: string;
  checked: boolean;
  value: string;
  [x: string]: any;
};

const PaymentMethodNavItem: React.FC<Props> = ({
  label,
  description,
  image,
  checked,
  ...props
}: Props) => {
  return (
    <div
      className={`form-check block mb-3 bg-white rounded-lg md:w-1/2 pr-2 mb-2 `}
    >
      <label>
        <input
          type="radio"
          className="pl-2 form-radio text-yoco-green-700"
          style={{
            visibility: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          {...props}
        />
        <div
          className={`flex h-28 shadow border-2 border-transparent rounded-lg p-3 justify-between items-start justify-start ${
            checked ? "option-selected" : ""
          }`}
        >
          <div className="flex-initial flex-shrink justify-center content-center">
            {image && (
              <img
                src={image}
                alt="Selected"
                className="w-4/5"
                style={{
                  position: "relative",
                  width: "60px",
                  height: "60px",
                }}
              />
            )}
            <div className="block font-bold text-base tracking-tight" />
          </div>

          <div className="flex-1 flex-shrink pl-4">
            <div className="font-bold text-sm ">{label}</div>
            <span className="text-sm pt-2 break-words">{description}</span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default PaymentMethodNavItem;
