import { SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import CardMain from "@/shared/components/CardMain";
import Header from "@/shared/components/Header";
import Segment from "@/shared/services/Segment";
import { PaymentMethod } from "@/shared/shop/api";
import CartSummaryMobileOffset from "@/shop/components/CartSummaryMobileOffset";
import CheckoutSidePanel from "@/shop/components/CheckoutSidePanel";
import DynamicPaymentMethod from "@/shop/pages/PaymentPage/DynamicPaymentMethod";
import PaymentMethodNav from "@/shop/pages/PaymentPage/PaymentMethodNav";
import { useCartStore } from "@/shop/store";
import Layout from "@/signup/components/Layout";
import StepProgressBar from "@/signup/components/StepProgressBar";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import withRedirectToProductCatalogueIfCartEmpty from "@/signup/middleware/withRedirectToProductCatalogueIfCartEmpty";
import SignupService from "@/signup/services/SignupService";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  cart: useCartStore(state => state.cart),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
});

const PaymentPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setActiveStep,
    cart,
    availablePaymentMethods,
    intendedPaymentMethod,
    setIntendedPaymentMethod,
  } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.PaymentPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_payment_start");
  }, [location]);

  useEffect(() => {
    if (cart?.balance_due.toString() === "0.00") {
      setIntendedPaymentMethod(PaymentMethod.FREE);
    }
  }, [cart, setIntendedPaymentMethod]);

  const onPaymentSuccess = useCallback(
    async (paymentMethod: PaymentMethod, isPaid?: boolean) => {
      await useSignupStore.getState().refreshSignup();
      const signup = useSignupStore.getState().getSignup();

      Segment.trackWithSignup("web_signup_payment_click_pay_button");

      SignupService.onFinaliseComplete(signup);

      // The CompletePage will by default look at the payment state on the cart.  For certain payment methods which do
      // as async callback to us, we don't know that the order is paid for by the time the customer sees this page.
      // We therefore allow for an "isPaid" query string param to be passed. This only affects what the customer sees
      // on this page, not the actual payment state of the order.
      let url = formatRoute(SignupRoute.CompletePage, { id: signup?.id });
      if (isPaid !== undefined) {
        url += `?isPaid=${isPaid ? "true" : "false"}`;
      }

      navigate(url);
    },
    [navigate]
  );

  return (
    <Layout>
      <div className="flex flex-row gap-x-4">
        <CardMain>
          <StepProgressBar />
          <Header title="Payment" />
          {!availablePaymentMethods?.includes(
            PaymentMethod.CREDIT_CARD_CHECKOUT
          ) && <PaymentMethodNav />}
          <DynamicPaymentMethod
            name={intendedPaymentMethod}
            onSuccess={onPaymentSuccess}
          />
          <CartSummaryMobileOffset />
        </CardMain>
        <CheckoutSidePanel isCartEditable={false} />
      </div>
    </Layout>
  );
};

export default withFetchSignup(
  withRedirectToProductCatalogueIfCartEmpty(PaymentPage)
);
