import { useFeatureFlags } from "@yoco/feature-flags";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import paymentLogoGrey from "@/assets/images/payment-logo-1.png";
import paymentLogoXneelo from "@/assets/images/xneelo-payment-logo.png";
import CONFIG from "@/config";
import TermsAndConditionsCheckbox from "@/shared/components/TermsAndConditionsCheckbox";
import Text from "@/shared/components/Text";
import Field from "@/shared/components/form/Field";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import HelperText from "@/shared/components/form/HelperText";
import Input from "@/shared/components/form/Input";
import PasswordInput from "@/shared/components/form/PasswordInput";
import Segment from "@/shared/services/Segment";
import { getString } from "@/shared/utils/appConfig";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import PasswordStrengthBar from "@/signup/components/PasswordStrengthBar";
import SubmitButton from "@/signup/components/SubmitButton";
import Validator from "@/signup/services/Validator";
import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { UserJourney } from "@/signup/types";
import LeadFormHeader from "./LeadFormHeader";

const useStore = () => ({
  createSignup: useSignupStore(state => state.createSignup),
  getSignup: useSignupStore(state => state.getSignup),
  resetSignup: useSignupStore(state => state.reset),
  resetCart: useCartStore(state => state.reset),
  resetSignupSteps: useStepStore(state => state.reset),
  addToCart: useCartStore(state => state.add),
  updateCart: useCartStore(state => state.updateCart),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
  userJourney: useAppConfigStore(state => state.userJourney),
  services: useAppConfigStore(state => state.services),
  autoAddProductID: useAppConfigStore(state => state.autoAddProductID),
  referralCode: useAppConfigStore(state => state.referralCode),
  resellerCode: useAppConfigStore(state => state.resellerCode),
  salesSmartProposalId: useAppConfigStore(state => state.salesSmartProposalId),
  promoCode: useAppConfigStore(state => state.promoCode),
  readerSerialNumber: useAppConfigStore(state => state.readerSerialNumber),
  preQualification: useAppConfigStore(state => state.preQualification),
  isInApp: useAppConfigStore(state => state.isInApp),
  isInHub: useAppConfigStore(state => state.isInHub),
});

const LeadForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const features = useFeatureFlags();
  const {
    createSignup,
    getSignup,
    resetSignup,
    resetSignupSteps,
    resetCart,
    addToCart,
    updateCart,
    getNextStepLink,
    userJourney,
    services,
    autoAddProductID,
    referralCode,
    resellerCode,
    salesSmartProposalId,
    promoCode,
    readerSerialNumber,
    preQualification,
    isInApp,
    isInHub,
  } = useStore();

  useEffect(() => {
    resetSignup();
    resetCart();
    resetSignupSteps();
    Segment.track("web_signup_start");
  }, [location, resetSignup, resetCart, resetSignupSteps]);

  const emailFromQueryString = getString("email")
    ? decodeURIComponent(getString("email") as string)
    : "";

  const mobileNumberFromQueryString = getString("mobileNumber")
    ? decodeURIComponent(getString("mobileNumber") as string)
    : "";

  const initialValues = {
    stage: SignupStep.HomePage,
    email: emailFromQueryString,
    password: "",
    mobile_number: mobileNumberFromQueryString,
    services,
    referral_code: referralCode || "",
    reseller_code: resellerCode || "",
    sales_smart_proposal_id: salesSmartProposalId || "",
    serial_number: readerSerialNumber || "",
    user_journey: userJourney || CONFIG.defaultUserJourney,
    marketing_opt: false,
    pre_qualification: preQualification,
  };

  yup.addMethod(yup.string, "emailAvailable", Validator.validateEmailAvailable);
  yup.addMethod(yup.string, "password", Validator.validatePassword);

  const schema = yup.object({
    email: (yup as any)
      .string()
      .email()
      .emailAvailable()
      .required()
      .label("email"),
    password: (yup as any)
      .string()
      .password()
      .required()
      .min(8)
      .label("password"),
    mobile_number: (yup as any)
      .string()
      .phone()
      .required()
      .label("mobile number"),
  });

  const onSubmit = async (values: FormikValues) => {
    const featureFlags = features.getEnabledFeatureNames();
    // eslint-disable-next-line no-param-reassign
    values.feature_flags = featureFlags;

    // eslint-disable-next-line no-param-reassign
    values.cart_uuid = getString("cartUUID");

    const isSignupCreated = await createSignup(values);

    if (isSignupCreated) {
      const signup = getSignup();
      Segment.identify({
        email: String(signup.email),
        signupUUID: String(signup.id),
        businessUUID: String(signup.business_uuid),
        userUUID: String(signup.user_uuid),
      });
      Segment.alias(String(signup.user_uuid));

      Segment.trackWithSignup("web_signup_submit_form");
      Segment.trackWithSignup("Lead Created", "milestones");

      const isCartEditable = !signup.cart.is_order;

      if (autoAddProductID && isCartEditable) {
        await addToCart(autoAddProductID);
      }

      if (featureFlags && isCartEditable) {
        await updateCart({
          feature_flags: featureFlags,
        });
      }

      // We update the promo code separately because if the promo code fails validation we don't
      // want to block other cart updates in the initial phase.
      if (promoCode && isCartEditable) {
        await updateCart({
          promo_code_str: promoCode,
        });
      }

      navigate(getNextStepLink());
    }
  };

  const isUserJourneyXneelo = userJourney === UserJourney.XNEELO;
  const hasReferralCode = !!referralCode;

  return (
    <div className="mx-auto bg-white p-4 sm:p-12 rounded-3xl max-w-full w-content">
      <LeadFormHeader
        userJourney={userJourney}
        hasReferralCode={hasReferralCode}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {(formik: any) => (
          <Form className="grid gap-x-8 gap-y-4 gap-3 sm:mb-8 mt-4 text-base">
            <Field
              name="email"
              type="email"
              placeholder="Email (this is your username for login) *"
              component={Input}
              data-testid={makeTestID("homePage", "email")}
            />

            <Field
              name="mobile_number"
              type="tel"
              placeholder="Mobile number *"
              component={Input}
              data-testid={makeTestID("homePage", "mobile")}
            />
            <div>
              <Field
                name="password"
                placeholder="Create a Password *"
                component={PasswordInput}
                data-testid={makeTestID("homePage", "password")}
              />
              <HelperText className="mt-1">
                The password must be a minimum of 8 characters.
              </HelperText>
              <PasswordStrengthBar password={formik.values.password} />
            </div>
            <label className="block">
              <Field
                name="marketing_opt"
                type="checkbox"
                className="text-left font-medium text-black"
              />{" "}
              Be the first to know about our offers and upcoming releases.
            </label>
            <div className="sm:block">
              <TermsAndConditionsCheckbox />
            </div>
            <div className="hidden sm:block p-button-color">
              <SubmitButton block testID={makeTestID("homePage", "continue")}>
                {isUserJourneyXneelo ? "Sign up for 3 months free" : "Continue"}
              </SubmitButton>
            </div>
            {!isInApp && !isInHub && (
              <div className="font-medium">
                <Text className="text-base ">
                  Already using Yoco?{" "}
                  <a
                    href="https://app.yoco.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Login
                  </a>
                </Text>
              </div>
            )}
            <div className="sm:hidden p-button-color">
              <SubmitButton block testID={makeTestID("homePage", "continue")}>
                {isUserJourneyXneelo ? "Sign up for 3 months free" : "Continue"}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
      <hr className="hidden sm:block" />

      <div className="flex sm:flex-row-reverse">
        {isUserJourneyXneelo ? (
          <div className="sm:w-16 mt-6 w-24 mx-auto">
            <img src={paymentLogoXneelo} alt="Payment logos" />
          </div>
        ) : (
          <div className="sm:w-1/3 mt-6 w-9/12 mx-auto">
            <img src={paymentLogoGrey} alt="Payment logos" />
          </div>
        )}
      </div>

      {isUserJourneyXneelo && (
        <div className="sm:w-4/6 sm:mx-auto mt-4 text-gray-500 text-xs text-center">
          All account and technical queries around Yoco payment services can be
          directed to the Yoco support team. You can reach them{" "}
          <a
            href="https://www.yoco.com/za/contact/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here
          </a>
        </div>
      )}
    </div>
  );
};

export default LeadForm;
