import { PaymentMethod } from "@/shared/shop/api";
import InstantEft from "@/shop/pages/PaymentPage/tabs/InstantEftBlackbirdPaymentMethod/InstantEft";
import { PaymentMethodProps } from "@/shop/types";
import React from "react";

const InstantEftBlackbirdPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const onTransactionComplete = async result => {
    if (result.status === "succeeded") {
      if (onSuccess) {
        onSuccess(PaymentMethod.INSTANT_EFT_BLACKBIRD);
      }
    }
  };

  return (
    <div>
      <InstantEft onTransactionComplete={onTransactionComplete} />
    </div>
  );
};

export default InstantEftBlackbirdPaymentMethod;
